.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-header {
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 1rem;
}
